import Trans from '../../components/trans'

interface Props {
    search: string
}

const NoResults = ({ search }: Props) => (
    <div className="no-results">
        <p>
            <Trans label="search.no-result.msg" />
        </p>
        <p className="term">{`“${search}”`}</p>
    </div>
)

export default NoResults
